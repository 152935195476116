/* =============================================================================
 * Links
============================================================================= */
a {
	color:inherit;
	font-weight:600;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
a:focus-visible {
	outline-offset:2px;
}
a:not([class]) {
	color:var(--color__link-color);
	font-weight:600;
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
a:not([class]):hover {
	color:var(--color__link-hover);
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
a:not([class]):focus {
	color:var(--color__link-active);
	text-decoration-thickness:0.5px;
}
div[class*="green"]:not(div[class*="-20"]) a:not([class]),
div[class*="--bg-red"] a:not([class]),
div[class*="--bg-darkgrey"] a:not([class]),
div[class*="--bg-black"] a:not([class]) {
	color:var(--color__base-white);
}
div[class*="green"]:not(div[class*="-20"]) a:not([class]):hover,
div[class*="--bg-red"] a:not([class]):hover,
div[class*="--bg-darkgrey"] a:not([class]):hover,
div[class*="--bg-black"] a:not([class]):hover {
	color:var(--color__ut_red-30);
}
div[class*="green"]:not(div[class*="-20"]) a:not([class]):focus,
div[class*="--bg-red"] a:not([class]):focus,
div[class*="--bg-darkgrey"] a:not([class]):focus,
div[class*="--bg-black"] a:not([class]):focus {
	color:var(--color__ut_yellow-50);
}

/* Links that open a new window
 ---------------------------------------------------------------------------- */
a[rel~="external"]::after:not(a.c-shortcuts-menu__item) {
	display:inline-block;
	position:relative;
	top:2px;
	box-sizing:content-box;
	width:14px;
	height:14px;
	padding-left:2px;
	background:url(../../components/icon/icons/external-link-red.svg?embed) no-repeat right center;
	background-size:contain;
	content:"";
}
.no-external[rel~="external"]::after {
	content:none;
}
.epi-quickNavigator-dropdown-arrow[rel~="external"]::after {
	content:none;
}