/* =============================================================================
 * Custom select
 *
 * 1. Hide dropdown arrow in IE10+.
 * 2. Background styling is put on a separate element placed after the actual
 *    select in the DOM to be able to act on states of the select.
 * 3. Make sure overlay is above actual select.
 * 4. Make sure we only interact with the actual select.
============================================================================= */
.c-searchable-dropdown {
	display:flex;
	position:relative;
	vertical-align:middle;
}
.c-searchable-dropdown__inner {
	z-index:2;
	width:100%;
	height:auto;
}
.c-searchable-dropdown__searchresult {
	display:none;
	position:absolute;
	top:42px;
	z-index:101;
	max-height:246px;
	width:100%;
	overflow:auto;
	margin:0 !important;
	border:1px solid #dbdbdb;
	border-bottom:none;
	box-shadow:0 4px 15px -8px rgba(0, 0, 0, 0.5);
	background-color:#fafafa;
	line-height:1.5 !important;
	cursor:pointer;
}
.c-searchable-dropdown__searchresult li {
	display:flex;
	justify-content:space-between;
	margin:0 !important;
	padding:5.5px 15px;
}
.c-searchable-dropdown__searchresult li:focus {
	outline:none;
	background-color:var(--color__grey-5);
}
.c-searchable-dropdown__searchresult li:hover:not(.c-searchable-dropdown__searchresult--noresult) {
	background-color:var(--color__grey-5);
}
.c-searchable-dropdown__searchresult--noresult {
	color:var(--color__grey-3);
	font-style:italic;
	cursor:auto;
}
.c-searchable-dropdown::-ms-expand {
	/* 1 */
	display:none;
}
.c-searchable-dropdown--min-width {
	min-width:250px;
}

/* .c-searchable-dropdown__input {
	height:100%;
	padding:8px 15px;
} */
.c-searchable-dropdown__input::-webkit-calendar-picker-indicator {
	opacity:0;
}
.c-searchable-dropdown__input::-webkit-list-button {
	opacity:0;
}

/* Hover and focus effect
----------------------------------------------------------------------------- */
.c-searchable-dropdown__select:hover + .c-searchable-dropdown__overlay,
.c-searchable-dropdown__select:hover + .c-searchable-dropdown__overlay .c-searchable-dropdown__arrow,
.c-searchable-dropdown__select:focus + .c-searchable-dropdown__overlay,
.c-searchable-dropdown__select:focus + .c-searchable-dropdown__overlay .c-searchable-dropdown__arrow {
	border-color:var(--color__primary-alt);
}


/* Disabled
----------------------------------------------------------------------------- */
.c-searchable-dropdown__select[disabled],
.c-searchable-dropdown__select[disabled] + .c-searchable-dropdown__overlay {
	opacity:0.6;
}
.c-searchable-dropdown__select[disabled]:hover + .c-searchable-dropdown__overlay,
.c-searchable-dropdown__select[disabled]:hover + .c-searchable-dropdown__overlay .c-searchable-dropdown__arrow,
.c-searchable-dropdown__select[disabled]:focus + .c-searchable-dropdown__overlay,
.c-searchable-dropdown__select[disabled]:focus + .c-searchable-dropdown__overlay .c-searchable-dropdown__arrow {
	border-color:var(--color__grey-4);
}

/* Invalid
----------------------------------------------------------------------------- */
.c-form-item--invalid .c-searchable-dropdown__select + .c-searchable-dropdown__overlay,
.c-form-item--invalid .c-searchable-dropdown__select:hover + .c-searchable-dropdown__overlay,
.c-form-item--invalid .c-searchable-dropdown__select:hover + .c-searchable-dropdown__overlay .c-searchable-dropdown__arrow,
.c-form-item--invalid .c-searchable-dropdown__select:focus + .c-searchable-dropdown__overlay,
.c-form-item--invalid .c-searchable-dropdown__select:focus + .c-searchable-dropdown__overlay .c-searchable-dropdown__arrow {
	border-color:var(--color__error);
}

@media screen and (max-width:350px) {
	.c-searchable-dropdown__searchresult li {
		flex-direction:column;
	}
}

@media screen and (min-width:992px) {
	.c-searchable-dropdown__searchresult {
		top:45px;
	}
	.c-searchable-dropdown__searchresult li {
		padding:4px 15px;
	}
}