.c-expandable-menu__item {
	display:flex;
	display:-webkit-flex;
	position:relative;
	-webkit-justify-content:space-between;
	justify-content:space-between;
	-webkit-flex-wrap:wrap;
	flex-wrap:wrap;
	color:var(--color__primary-alt);
}
.c-expandable-menu > .c-expandable-menu__item--expanded::after {
	position:absolute;
	top:1px;
	bottom:0;
	left:-1px;
	z-index:1;
	width:3px;
	background:var(--color__grey-2);
	content:"";
}
.c-expandable-menu__item > ul {
	flex-basis:100%;
}
.c-expandable-menu__button {
	flex:0 1 auto;
	width:54px;
	outline:none;
}
.c-expandable-menu__button:focus {
	outline:1px dotted #000;
}
.c-expandable-menu__button--wide {
	width:80px;
}
.c-expandable-menu__button-icon {
	transition:transform 0.2s;
}
.c-expandable-menu__button--expanded .c-expandable-menu__button-icon {
	transform:rotate(180deg);
}
.c-expandable-menu__link {
	display:block;
	position:relative;
	-webkit-flex:1;
	flex:1;
	padding:calc(var(--space) / 2) calc(var(--space) - (var(--space) / 4)) calc(var(--space) / 2) var(--space);
	text-decoration:none;
}
.c-expandable-menu__link,
.c-expandable-menu__link:hover {
	color:var(--color__base-black);
}
.c-expandable-menu__link--selected {
	font-weight:700;
}
.c-expandable-menu__link--selected::before {
	position:absolute;
	top:0;
	bottom:0;
	left:-1px;
	z-index:4;
	width:3px;
	background:var(--color__primary);
	content:"";
}
.c-expandable-menu__item li a {
	padding-left:calc(28px * 2);
	padding-left:calc(var(--space) * 2);
}
.c-expandable-menu__item li li a {
	padding-left:calc(28px * 3);
	padding-left:calc(var(--space) * 3);
}
.c-expandable-menu__item li li li a {
	padding-left:calc(28px * 3);
	padding-left:calc(var(--space) * 4);
}

/* Mobile navigation list */
.c-expandable-menu--mobile {
	background:var(--color__base-white);
}
.c-expandable-menu--mobile .c-expandable-menu__item {
	border:0;
	border:1px solid;
	border-color:var(--color__ut_green-20);
	background-color:var(--color__base-white);
	color:var(--color__base-black);
}
.c-expandable-menu--mobile > .c-expandable-menu__item:first-child {
	border:none;
}
.c-expandable-menu--mobile .c-expandable-menu__link {
	display:block;
	flex:unset;
	margin-left:var(--space);
	padding:16px 0;
	font-size:18px;
	line-height:24px;
}
.c-expandable-menu--mobile > .c-expandable-menu__item > .c-expandable-menu__link {
	font-weight:600;
}
.c-expandable-menu--mobile .c-expandable-menu__link.c-expandable-menu__link--selected {
	outline:none;
	border-bottom:3px solid var(--color__primary);
	color:var(--color__primary);
	font-weight:600;
}
.c-expandable-menu--mobile .c-expandable-menu__item--alt,
.c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link,
.c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link:hover,
.c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link:focus,
.c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link + .c-expandable-menu__button,
.c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link + .c-expandable-menu__button:hover,
.c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link + .c-expandable-menu__button:focus {
	background:var(--color__ut_darkgreen);
	color:var(--color__base-white);
	transition:background-color 0.2s ease;
}
.c-header--private .c-expandable-menu--mobile .c-expandable-menu__item--alt,
.c-header--private .c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link,
.c-header--private .c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link:hover,
.c-header--private .c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link:focus,
.c-header--private .c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link + .c-expandable-menu__button,
.c-header--private .c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link + .c-expandable-menu__button:hover,
.c-header--private .c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link + .c-expandable-menu__button:focus {
	background:var(--color__ut_green);
}
.c-expandable-menu--mobile .c-expandable-menu__item--alt > .c-expandable-menu__link--selected {
	border-color:var(--color__base-white);
	color:var(--color__base-white);
}
.c-expandable-menu--mobile .c-expandable--menu__item--alt > .c-expandable-menu__link--selected + .c-expandable-menu__button {
	background:var(--color__primary);
	color:var(--color__base-white);
}
.c-expandable-menu--mobile .c-expandable-menu__button {
	width:64px;
	background:var(--color__base-white);
}
.c-expandable-menu--mobile .c-expandable-menu__button:focus {
	outline-offset:-1px;
}

/* Remove pseudo elements */
.c-expandable-menu--mobile .c-expandable-menu__link::before,
.c-expandable-menu--mobile .c-expandable-menu__item::after {
	content:none;
}
.c-expandable-menu--mobile .c-expandable-menu__wrapper .c-expandable-menu__item {
	border:none;
}
.c-expandable-menu--mobile .c-expandable-menu__wrapper .c-expandable-menu__item > .c-expandable-menu__link {
	margin-left:calc(var(--space) * 2);
}
.c-expandable-menu--mobile .c-expandable-menu__wrapper > .c-expandable-menu__item > .c-expandable-menu__link {
	margin-left:calc(var(--space) * 1.5);
}
.c-expandable-menu--mobile .c-expandable-menu__wrapper > .c-expandable-menu__item,
.c-expandable-menu--mobile .c-expandable-menu__wrapper > .c-expandable-menu__item > .c-expandable-menu__button {
	background-color:var(--color__ut_grey-30);
}

/* Top level buttons */
.c-expandable-menu__item--top-level[class] > button {
	display:flex;
	justify-content:space-between;
	width:100%;
	margin:0;
	padding-right:20px;
	padding-left:20px;
}
.c-expandable-menu__item--switchtype {
	display:flex;
}

@media screen and (min-width:768px) and (max-width:1024px) {
	.c-expandable-menu__link {
		padding:10px 15px;
	}
	.c-expandable-menu__item li a {
		padding-left:15px;
	}
	.c-expandable-menu__item li li a {
		padding-left:25px;
	}
	.c-expandable-menu__item li li li a {
		padding-left:35px;
	}
}