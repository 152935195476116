.c-blob {
	display:flex;
	position:absolute;
	justify-content:center;
	color:#333;
}
.c-blob--top {
	top:20px;
}
.c-blob--bottom {
	bottom:20px;
}
.c-blob--left {
	left:20px;
}
.c-blob--right {
	right:20px;
}
.c-teaser__content + .c-blob--bottom {
	position:static;
	order:-1;
	margin:0 20px 20px;
}
.c-teaser__content + .c-blob--right {
	align-self:flex-end;
}
.c-blob__inner {
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	width:100%;
	font-family:"verveine", sans-serif;
	text-align:center;
	text-wrap:balance;
	gap:10px;
}
.c-blob__inner p {
	font-size:16px;
	line-height:22px;
}
.c-blob__heading {
	margin:0 !important;
	font-weight:400 !important;
	font-size:20px !important;
	line-height:14px !important;
	text-transform:uppercase;
	letter-spacing:1px;
}
.c-blob--small {
	width:144px;
	height:148px;
	padding:15px;
	background:url(/ui-project/images/blob-small.svg) no-repeat center center;
	background-size:contain;
}
.c-blob--large {
	width:201px;
	height:153px;
	padding:20px;
	background:url(/ui-project/images/blob-large.svg) no-repeat center center;
	background-size:contain;
}
.c-blob--original {
	width:144px;
	height:139px;
	padding:20px;
	background:url(/ui-project/images/blob.svg) no-repeat center center;
	background-size:contain;
}
.c-blob--large .c-blob__inner,
.c-blob--original .c-blob__inner {
	width:90%;
}
.c-blob--small.c-blob--bg-dark {
	background-image:url(/ui-project/images/blob-small--dark.svg);
	color:#fff;
}
.c-blob--large.c-blob--bg-dark {
	background-image:url(/ui-project/images/blob-large--dark.svg);
	color:#fff;
}
.c-blob--original.c-blob--bg-dark {
	background-image:url(/ui-project/images/blob--dark.svg);
	color:#fff;
}

@media(max-width:768px) {
	.c-blob__inner {
		gap:2px;
	}
	.c-blob--original .c-blob__inner {
		width:100%;
	}
	.c-blob__heading {
		font-size:16px;
	}
	.c-blob__inner p {
		font-size:13px;
		line-height:18px;
	}
	.c-blob--small {
		width:107px;
		height:110px;
		padding:10px;
	}
	.c-blob--large {
		width:126px;
		height:111px;
		padding:15px;
	}
	.c-blob--original {
		width:105px;
		height:101px;
		padding:15px;
	}
}