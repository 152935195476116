.c-action-menu {
	display:flex;
	display:-webkit-flex;
	-webkit-flex-shrink:0;
	flex-shrink:0;
}
.c-action-menu__list {
	display:flex;
	display:-webkit-flex;
	-webkit-justify-content:space-between;
	justify-content:space-between;
	color:#fff;
}
.c-action-menu__item {
	display:flex;
	display:-webkit-flex;
	-webkit-align-self:center;
	align-self:center;
	padding-left:var(--space);
}
.c-action-menu__item--cart {
	padding-left:calc(var(--space) + 10px);
}
.c-main-navigation__support svg {
	transition:fill 0.2s ease;
	fill:#fff;
}
.c-main-navigation__support .c-main-navigation__item {
	padding-bottom:1.5px;
}
.c-main-navigation[data-loggedin="true"] .c-main-navigation__support .c-action-menu__icons {
	left:-25px;
}
.c-action-menu__item:first-child {
	padding-left:0;
}
.c-action-menu__link {
	display:inline-block;
	position:relative;
	padding:15px 0 5px !important;
	color:#fff;
	font-size:var(--font-size-large);
	text-align:center;
	text-decoration:none;
}
#cart {
	display:flex;
}
.c-action-menu__link {
	color:#fff;
}
.c-action-menu__link:hover,
.c-action-menu__link:focus,
.c-action-menu__link:focus-visible {
	outline:none;
	border-bottom:3px solid #fff;
	color:#fff;
	color:#fff;
	text-decoration:none;
	fill:#fff;
}
.c-action-menu__link:hover .c-action-menu__cart-count {
	box-shadow:0 0 0 2px #fff;
	background-color:#fff;
}
.c-action-menu__icon-text {
	font-weight:600;
	line-height:21px;
	vertical-align:middle;
}
.c-action-menu__item--cart .c-action-menu__icon-text {
	padding-left:7px;
}
.c-action-menu__cart-count {
	position:absolute;
	top:11px;
	right:calc(100% - 10px);
	min-width:20px;
	height:20px;
	border-radius:11px;
	background-color:var(--color__base-white);
	color:#000;
	font-weight:700;
	font-size:11px;
	line-height:18px;
	text-align:center;
	transition:all 0.2s ease;
}
.c-header-mobile .c-action-menu__cart-count {
	top:-4px;
	right:calc(100% - 24px);
	min-width:17px;
	height:17px;
	line-height:16px;
}
.c-action-menu__cart-count--new-item {
	transform:scale(1.2);
}
.c-action-menu__cart-count--is-empty {
	width:1px;
	height:1px;
	overflow:hidden;
	clip:rect(1px, 1px, 1px, 1px);
	padding:0;
	border:none;
}


/* Width adjustments for some icons to remove whitespace from the SVG viewbox.
 *
 * Double class selectors are used to increase specificity since rules in
 * c-icon would otherwise win if c-icon is loaded after this component.
----------------------------------------------------------------------------- */
.c-action-menu__icon.c-action-menu__icon--padlock,
.c-action-menu__icon.c-action-menu__icon--padlock > svg {
	width:16px;
}
.c-action-menu__icon.c-action-menu__icon--cart,
.c-action-menu__icon.c-action-menu__icon--cart > svg {
	width:22px;
}