/* =============================================================================
 * Form item
============================================================================= */


/* Don't break form elements
----------------------------------------------------------------------------- */
.c-form-item--no-break {
	display:flex;
	align-items:center;
}


/* Label
----------------------------------------------------------------------------- */
.c-form-item__label {
	margin-bottom:8px;
	vertical-align:middle;
}
.c-form-item__label--before {
	margin-right:calc(var(--space) / 2);
	margin-bottom:0;
}
.c-form-item__label--after {
	margin-bottom:0;
	margin-left:calc(var(--space) / 2);
}
.c-form-item__label-extra {
	color:#767676;
}
.c-form-item__label--disabled {
	opacity:0.5;
}


/* Field
----------------------------------------------------------------------------- */
.c-form-item__field {
	min-height:42px;
	padding:8px 15px;
	border:1px solid var(--color__grey-4);
	background:#fff;
}
.c-form-item__field[disabled],
.c-form-item__field[readonly] {
	opacity:0.6;
}
.c-form-item__field:not([disabled]):hover,
.c-form-item__field:not([disabled]):focus {
	border-color:var(--color__primary-alt);
}
.c-form-item__field:not([disabled]):focus {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
}

/*
 * Invalid
 */
.c-form-item--invalid .c-form-item__field,
.c-form-item--invalid .c-form-item__field:hover,
.c-form-item--invalid .c-form-item__field:focus {
	border-width:2px;
	border-color:var(--color__error);
	background-color:var(--color__error-light);
}

/*
 * Valid
 */
.c-form-item--valid .c-form-item__field,
.c-form-item--valid .c-form-item__field:hover,
.c-form-item--valid .c-form-item__field:focus {
	border-color:var(--color__primary);
}


/* Text
----------------------------------------------------------------------------- */
.c-form-item .c-form-item__text {
	margin-bottom:0 /* override s-text margins */;
}
.c-form-item__text {
	margin-top:5px;
	color:#666;
	line-height:1.25;
}

/*
 * Error
 */
.c-form-item__text--error-text {
	color:var(--color__error);
	font-weight:600;
}

/*
 * Disabled
 */
.c-form-item__text--disabled {
	opacity:0.5;
}


/* Required indicator
----------------------------------------------------------------------------- */
.c-form-item__required-indicator.c-form-item__required-indicator {
	display:inline-block;
	font-weight:bold;
	font-style:normal;
	font-size:1.5em;
	line-height:1;
	vertical-align:top;
}