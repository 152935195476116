/* =============================================================================
 * Custom checkbox
 *
 * 1. Fix flex shrink problem
 * 2. Make room for the border
 * 3. Clip the SVG
 * 4. Needs to be smaller to compensate for the thicker border
============================================================================= */
.c-custom-checkbox {
	display:inline-block;
	position:relative;
	min-width:24px; /* 1 */
	width:24px;
	height:24px;
	vertical-align:middle;
}
.c-custom-checkbox__input {
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	opacity:0;
}
.c-custom-checkbox__overlay {
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	border:1px solid var(--color__grey-4);
	background:#fff;
	pointer-events:none;
}
.c-custom-checkbox__overlay--thick-border {
	border-width:2px;
}
.c-custom-checkbox__overlay .c-custom-checkbox__mark {
	display:flex;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	justify-content:center;
	align-items:center;
	width:22px; /* 2 */
	height:22px;
	overflow:hidden; /* 3 */
	background:var(--color__ut_darkgreen);
	color:#fff;
	opacity:0;
}
.c-custom-checkbox__overlay--thick-border .c-custom-checkbox__mark {
	width:20px; /* 4 */
	height:20px;
}


/* Checked
----------------------------------------------------------------------------- */
.c-custom-checkbox__input:checked + .c-custom-checkbox__overlay .c-custom-checkbox__mark {
	opacity:1;
}

/* Hover
----------------------------------------------------------------------------- */
.c-custom-checkbox__input:not([disabled]):hover + .c-custom-checkbox__overlay {
	border-color:var(--color__primary-alt);
}

/* Focus
----------------------------------------------------------------------------- */
.c-custom-checkbox__input:not([disabled]):focus + .c-custom-checkbox__overlay {
	outline:2px dashed var(--color__primary-dark);
	outline-offset:4px;
	border-color:var(--color__primary-alt);
	color:var(--color__base-white);
}

/* Disabled
----------------------------------------------------------------------------- */
.c-custom-checkbox__input[disabled] + .c-custom-checkbox__overlay {
	opacity:0.4;
}