/* =============================================================================
 * Accordion
============================================================================= */
.c-accordion {
	margin-bottom:var(--space);
	padding:calc(var(--space) - 5px);
	padding-bottom:calc(var(--space) - 15px);
	background-color:#eff5f9;
	color:var(--color__base-black);
}
.c-accordion--alt-styling {
	margin-bottom:0;
	padding-left:0;
	border-top:1px solid var(--color__grey-4);
	border-bottom:0;
	background-color:var(--color__base-white);
}
.c-accordion--alt-styling:last-child {
	margin-bottom:var(--space);
	border-bottom:1px solid var(--color__grey-4);
}
.c-accordion + .c-accordion {
	margin-top:0;
}
.c-accordion__wrapper {
	margin-bottom:var(--space);
}
.c-accordion__click-area {
	position:relative;
}
.c-accordion__trigger[class] {
	margin:0 0 calc(var(--space) / 2);
	border:none;
}
.c-accordion__content {
	margin-top:calc(var(--space) / 2);
	margin-bottom:0;
	font-size:var(--font-size-large);
}
.c-accordion .c-accordion__content p {
	margin-right:40px;
}
.c-accordion .c-accordion__content ul {
	list-style:inside;
}
.c-accordion .c-accordion__content p:last-child {
	margin-bottom:10px;
}
.c-accordion--is-collapsed .c-accordion__content {
	display:none;
}
.c-accordion--is-expanded .c-accordion__content {
	display:block;
}
.c-accordion__button {
	display:flex;
	justify-content:space-between;
	align-items:center;
	width:100%;
	padding:0;
	border:none;
	background-color:transparent;
	color:#000;
	text-align:left;
	text-decoration:none;
	cursor:pointer;
}
.c-accordion__button::after {
	display:block;
	position:absolute;
	top:0;
	right:0;
	bottom:0;
	left:0;
	content:"";
}
.c-accordion__button > span:first-child {
	flex:1;
}
.c-accordion--alt-styling .c-accordion__button {
	font-weight:600;
}
.c-accordion__icon[class] {
	flex:0 0 24px;
	width:24px;
	height:24px;
	margin-right:calc(var(--space) / 2);
	margin-left:var(--space);
	border-radius:50%;
	background:#000;
	color:#fff;
	transition:transform 0.2s ease;
	transform:rotate(0);
}
.c-accordion__icon[class] svg {
	margin-top:1px;
}
.c-accordion--is-expanded:not(.c-accordion--alt-styling) .c-accordion__icon {
	transform:rotate(180deg);
}
.c-accordion--alt-styling .c-accordion__icon {
	flex:0 0 32px;
	border-radius:0;
	background-color:transparent;
	color:var(--color__base-black);
}
.c-accordion--alt-styling .c-accordion__icon,
.c-accordion--alt-styling .c-accordion__icon svg {
	width:32px;
	height:32px;
}

@media screen and (max-width:768px) {
	.c-accordion__heading {
		hyphens:auto;
		overflow-wrap:break-word;
	}
}