/* =============================================================================
* Custom number input
============================================================================= */
.c-input-number {
	display:inline-block;
}
.c-input-number__input-container {
	position:relative;
	max-width:140px;
	width:100%;
	border:1px solid var(--color__grey-4);
	background:#fff;
}
.js .c-input-number__input-container {
	padding:0 36px;
}
.c-input-number--full-width {
	max-width:none;
}
.c-input-number__input {
	min-width:40px;
	height:41px;
	padding:8.5px 4px;
	text-align:center;
}
.c-input-number__label {
	margin-bottom:8px;
	font-weight:600;
}
.js .c-input-number__input {
	border-right:1px solid var(--color__grey-4);
	border-left:1px solid var(--color__grey-4);
}
.js .c-input-number__input:focus {
	outline:1px solid #000;
	outline-offset:0;
}
.no-js .c-input-number__decrement-button,
.no-js .c-input-number__increment-button {
	display:none;
}
.c-input-number__decrement-button,
.c-input-number__increment-button {
	position:absolute;
	top:0;
	bottom:0;
	width:36px;
}
.c-input-number__decrement-button {
	left:0;
}
.c-input-number__increment-button {
	right:0;
}

@media (min-width:992px) {
	.c-add-to-cart__form .c-input-number__input {
		height:46px;
	}
}

@media screen and (max-width:768px) {
	.c-input-number__input-container {
		max-width:120px;
	}
}