/* =============================================================================
 * Page list
 *
 * [1] Remove the bottom border and margin from the last item on a page unless
 *     it is the last page. We’re using :nth-last-child(n + 2) instead of
 *     :not(:last-child) to work around a bug in Safari 11.
 * [2] min-height forces IE11 to collapse the height of .o-grid > [data-size] > * even if it
 *     contains an img. See https://github.com/philipwalton/flexbugs/issues/75.
============================================================================= */
.c-page-list {
	margin-bottom:var(--space);
}
.c-page-list a:not([class]) {
	font-weight:600;
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-page-list--alt-style {
	margin-left:0;
}
.c-page-list__list[class] {
	margin:0;
}
.c-page-list__list-item {
	display:flex;
	padding:var(--space) 0;
	border-top:1px solid var(--color__grey-1);
}
.c-page-list__list-item:last-child {
	margin-bottom:var(--space);
	border-bottom:1px solid var(--color__grey-1);
}
.c-page-list--alt-style .c-page-list__list-item {
	margin-bottom:var(--space) !important;
	padding:var(--space);
	border:1px solid var(--color__grey-2);
	background-color:var(--color__base-white);
}
.c-page-list--alt-style .c-page-list__list-item--column {
	flex-direction:column;
	padding:0;
	border:0;
}
.c-page-list__page:nth-last-child(n + 2) .c-page-list__list-item:last-child {
	margin-bottom:0; /* [1] */
	border-bottom:none;
}
.c-page-list__bestbet {
	padding-top:var(--space);
	border:2px solid #f4f4f4;
}
.c-page-list__bestbet:first-child {
	padding-top:0;
}
.c-page-list__bestbet-heading {
	padding:8px;
	background:var(--color__ut_darkgrey-20);
}
.c-page-list__bestbet-heading h3 {
	color:var(--color__base-black);
	font-weight:700;
}
.c-page-list__bestbet .c-page-list__list-item {
	padding:var(--space);
	border:none;
}
.c-page-list__item-image {
	flex:0 1 150px;
	min-height:1px; /* [2] */
	margin-right:var(--space);
}
.c-page-list--alt-style .c-page-list__item-image {
	flex:0 1 250px;
}
.c-page-list__item-content {
	flex:1 1 300px;
}
.c-page-list__list-item--column .c-page-list__item-image {
	margin:0;
}
.c-page-list__list-item--column .c-page-list__item-image,
.c-page-list__list-item--column .c-page-list__item-content {
	flex:0;
}
.c-page-list__list-item--column .c-page-list__item-content {
	padding:var(--space);
}
.c-page-list__item-content > * {
	margin-bottom:calc(var(--space) / 2);
}
.c-page-list__item-content > *:last-child {
	margin-bottom:0;
}
.c-page-list__item-keytag {
	display:inline-block;
	padding:5px 20px;
	background:var(--color__ut_yellow);
	color:var(--color__base-black);
	font-weight:700;
}
.c-page-list__item-heading {
	font-weight:700;
	font-size:18px;
	line-height:inherit;
}

/* .c-page-list__item-heading font-size:var(--font-size-heading3); */
.c-page-list--alt-style .c-page-list__item-heading {
	margin-bottom:calc(var(--space) / 4);
	font-weight:400;
	font-size:var(--font-size-intro-lg);
	line-height:32px;
}
.c-page-list__item-heading[class] a {
	position:relative;
	z-index:1;
}
.c-page-list--alt-style .c-page-list__item-heading a {
	font-size:inherit;
	line-height:inherit;
}
.c-page-list--alt-style a:not(:has(.c-icon)) {
	text-decoration:underline;
	text-decoration-thickness:0.5px;
	text-underline-offset:0.2em;
}
.c-page-list--alt-style .c-page-list__item-heading a[rel~="external"]::after {
	top:4px;
	width:24px;
	height:24px;
	margin-left:4px;
}
.c-page-list__item-tags {
	margin:0;
	list-style:none;
}
.c-page-list__item-tags a {
	position:relative;
	z-index:1;
}
.c-page-list__item-tags.c-page-list__item-tags li {
	display:inline-block;
	margin:0;
}

/* .c-page-list__item-tags li + li::before {
	display:inline-block;
	width:1px;
	height:16px;
	margin:0 0.5em 0 calc(0.5em - 1px);
	background-color:var(--color__base-black);
	content:"";
	vertical-align:-2px;
} */
.c-page-list__item-meta {
	display:block;
	width:100%;
}
.c-page-list__item-meta > * {
	display:block;
}
.c-page-list__item-meta > *:not(.c-page-list__item-date) {
	font-size:var(--font-size-default);
}
.c-page-list__item-date {
	font-weight:600;
}
.c-page-list--alt-style .c-page-list__item-date {
	font-weight:600;
	font-size:var(--font-size-default);
}
.c-page-list--alt-style .c-page-list__item-date time {
	margin-bottom:inherit;
	font-size:inherit;
	line-height:inherit;
}
.c-page-list__item-meta dt {
	float:left;
	margin-right:calc(var(--space) / 4);
}
.c-page-list__icon {
	vertical-align:initial;
}
.c-page-list--alt-style .c-page-list__item-content p {
	margin:0;
	font-size:var(--font-size-default);
}
.c-page-list__item-category[class] {
	display:flex;
	flex-wrap:wrap;
	list-style-type:none;
}
.c-page-list__item-category--inline[class] {
	margin:0;
}
.c-page-list__item-category[class] li {
	margin:var(--space) var(--space) 0 0;
}
.c-page-list__item-category--inline[class] li {
	margin-top:0;
	margin-bottom:var(--space);
}
.c-page-list__item-category-box[class] {
	display:flex;
	align-items:center;
	margin:0;
	padding:10px;
	border-bottom:none;
	border-radius:2px;
	background-color:var(--color__cue_light);
	color:var(--color__primary-dark);
	font-weight:600;
	font-size:var(--font-size-intro);
	line-height:1;
	text-decoration:none;
}
.c-page-list__item-category-box[class]:focus,
.c-page-list__item-category-box[class]:hover {
	border-bottom:none;
	text-decoration:none;
}
.c-page-list--alt-style .c-page-list__item-category-box {
	font-size:var(--font-size-default);
}
.c-page-list__item-category li span.c-icon {
	margin-right:8px;
}

@media (min-width:992px) {
	.c-page-list--alt-style .c-page-list__item-date,
	.c-page-list--alt-style .c-page-list__item-content p,
	.c-page-list--alt-style .c-page-list__item-category-box {
		font-size:var(--font-size-large);
	}
}

@media screen and (max-width:768px) {
	.c-page-list__bestbet .c-page-list__list-item {
		padding:calc(var(--space) / 2);
	}
	.c-page-list {
		margin-left:0;
	}
	.c-page-list--alt-style .c-page-list__item-heading {
		font-size:var(--font-size-intro);
	}
	.c-page-list--alt-style .c-page-list__item-heading a[rel~="external"]::after {
		width:20px;
		height:20px;
	}
	.c-page-list--alt-style .c-page-list__item-date,
	.c-page-list__item-category--inline .c-page-list__item-category-box[class] {
		font-size:var(--font-size-large);
	}
}

@media screen and (max-width:420px) {
	.c-page-list__list-item {
		flex-direction:column;
	}
	.c-page-list--alt-style .c-page-list__list-item {
		margin-right:-20px;
		margin-left:-20px;
		padding:0;
		border:0;
		border-bottom:1px solid var(--color__grey-2) !important;
	}
	.c-page-list__item-image {
		margin:0 0 calc(var(--space) / 2);
	}
	.c-page-list__item-image,
	.c-page-list__item-content,
	.c-page-list--alt-style .c-page-list__item-image {
		flex:1 0 auto;
	}
	.c-page-list--alt-style .c-page-list__item-image {
		margin:0;
	}
	.c-page-list--alt-style .c-page-list__item-image img {
		width:100%;
	}
	.c-page-list--alt-style .c-page-list__item-content {
		padding:var(--space);
	}
}